import React from 'react'
import Layout from '../components/resuable/layout/layout'
import FaqHero from '../components/page/faq-components/faq-hero/faq-hero'
import { graphql } from 'gatsby'
import FaqContent from '../components/page/faq-components/faq-content/faq-content'
import SEO from '../components/resuable/seo/seo'
import Joinus from '../components/resuable/joinus/joinus'

const Faq = ({ data }) => {
  const { yoast_meta, acf } = data.wordpressPage;
  console.log(acf.faqs);
  return (
    <Layout>
      <SEO 
        title={yoast_meta.yoast_wpseo_title}
        description={yoast_meta.yoast_wpseo_metadesc}
      />
      <FaqHero />
      <FaqContent 
        faqs={acf.faqs}
      />
      <Joinus />
    </Layout>
  )
}

export const query = graphql`
  query faqPageDetails {
    wordpressPage(slug: {eq: "faq"}) {
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        faqs {
          question
          answer
          isDefaultOpen
        }
      }
    }
  }
`

export default Faq
