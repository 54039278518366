import React from 'react'
import styled from 'styled-components'
import Accordian from '../../../resuable/accordian/accordian'
import Section from '../../../resuable/section/section'

const StyledFaqContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  div.faq {
    width: 100%;
    max-width: 650px;
    margin: 10px 0px;
    border: none;
    .container__title {
      padding: 8px 12px;
      background: ${props => props.theme.name === 'dark' ? '#1E1E1E' : '#eee'};
      border-bottom: 0px;
      h5 {
        color: ${props => props.theme.name === 'dark' ? '#fdfdfd' : '#555'};
        font-weight: 400;
        font-size: 18px;
      }
    }
    .container__body {
      box-sizing: border-box;
      padding: inherit 8px;
      background: ${props => props.theme.name === 'dark' ? '#000' : '#ddd'};
      color: ${props => props.theme.name === 'dark' ? '#ccc' : '#444'};
    }
  }
`

const FaqContent = ({
  faqs
}) => {
  console.log(faqs)
  return (
    <Section>
      <StyledFaqContent>
        {
          faqs.map((faq, index) => {
            return (
              <Accordian
                className="faq"
                key={index}
                title={faq.question}
                isDefaultOpen={faq.isDefaultOpen === 'open' ? true : false}
              >
                {faq.answer}
              </Accordian>
            )
          })
        }
      </StyledFaqContent>
    </Section>
  )
}

FaqContent.defaultProps = {

}

export default FaqContent