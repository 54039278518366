import React from 'react'
import styled from 'styled-components'
import Hero from '../../../resuable/hero/hero'

const StyledFaqHero = styled.div`
  
`

const FaqHero = (props) => {
  return (
    <StyledFaqHero>
      <Hero 
        title="FAQ"
      />
    </StyledFaqHero>
  )
}

FaqHero.defaultProps = {

}

export default FaqHero